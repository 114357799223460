import { PageLayout } from "../components/layout/PageLayout";
import React from "react";
import HomeTest from "../components/modules/HomeTest";

const Test = () => {
  return (
    <PageLayout>
      <div className="section"></div>
      <HomeTest />
      <div className="section"></div>
      <div className="section"></div>
    </PageLayout>
  );
};

export default Test;
